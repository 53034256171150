@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("./fonts/rubik-v14-latin-regular.woff2") format("woff2"),
    url("./fonts/rubik-v14-latin-regular.woff") format("woff");
}

body {
    font-family: "Rubik";
    background: "#F2F3F5";
    color: rgb(65, 65, 65)
}

.pointer {
    cursor: pointer;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.MuiDataGrid-columnSeparator {
    visibility: hidden;
}

.MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 !important;
}

.swal2-container {
    z-index: 10000 !important;
}
